import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProjectDetails from "../../images/projects/Alsea_Avilion-Gardens_Altiplano.yml"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"

const Alsea = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query($floorPlan: String = "/images/projects/alsea/04 FLOOR PLANS/") {
      logo: file(relativePath: { eq: "alsea-logo.png" }) {
        ...ImgL
      }
      siteDevImg: file(relativePath: { eq: "projects/alsea/ALSEA SDP.png" }) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "TownhouseUnits", num: "160" },
    { metric: "TypesofUnits", num: "1" },
    { metric: "TypicalLotArea", num: "38" },
    { metric: "TypicalFloorArea", num: "42" },
  ])
  const props: LocationTemplateProps = {
    title: "Alsea",
    logo,
    logoSz: 105,
    location: "New Guinea St., Brgy. Don Bosco, Parañaque City",
    metrics,
    carouselImgs: [
      require("../../images/projects/alsea/01 ALSEA GATE.jpg"),
      require("../../images/projects/alsea/02 ALSEA UNIT.jpg"),
      require("../../images/projects/alsea/03 ALSEA UNIT.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Alsea is strategically a low-density community which gives its users the opportunity to fully enjoy all its privileges. In this gated community, security and privacy is of top most priority. Each unit enjoys its own private property and is designed to have easy access to its amenities.",
    },
    amenities: {
      description:
        "Take a stroll through the amenities that Alsea has to offer. Although a small community, Alsea has its own chapel, basketball court, and clubouse available for everyone’s use.",
      items: [
        {
          name: "Chapel",
          description:
            "Have some inner peace and time for reflection in the Chapel. ",
          img: require("../../images/projects/alsea/amenities/01 ALSEA CHAPEL .jpg"),
        },
        {
          name: "Basketball Court",
          description:
            "Enjoy the outdoors while having fun with physical activities such as a fun game of basketball. ",
          img: require("../../images/projects/alsea/amenities/02 ALSEA MULTIPURPOSE _ BASKETBALL.jpg"),
        },
        {
          name: "Multipurpose Clubhouse",
          description:
            "The open-air Clubhouse is ready for anyone's use for events, gatherings, and other activities. It’s surrounded by other amenities which makes it easy to go from one activity to another. ",
          img: require("../../images/projects/alsea/amenities/03 ALSEA MULTIPURPOSE.jpg"),
        },
      ],
    },
    floorPlans: {
      description:
        "Alsea offers a two-storey townhouse with a modern and clean design. Each unit sits on a typical lot area of 38sq.m with a typical floor area of 42sq.m and is able to fit two (2) bedrooms, one (1) toilet & bath, it’s own private service area, and a spacious dining/living/kitchen area. ** All Floor Plans below are for suggested layout only.",
      items: [
        [
          {
            name: "Townhouse",
            fpImg: fpImg("ALSEA FP"),
            pImg: fpImg("ALSEA PERSP"),
          },
        ],
      ],
    },
    projDetails: {
      ...ProjectDetails,
      name: "Alsea",
      description: `Alsea is a small community consisting of affordable two-storey townhouses. Engulfed in lush greens, is a development sitting on over 1-hectare of prime land at the heart of Parañaque City- a city where tourism is one its major industries.

      Exactly located in No. 25 New Guinea St., Better Living Subdivision, Brgy. Don Bosco, Parañaque City, Alsea offers total serenity away from the hustle and bustle of the city but still remains near enough to enjoy all it has to offer.`,
    },
  }
  return <Template {...props} />
}

export default Alsea
